@import "config/config";
@import "config/fontawesome";

.password-plain-text {
    &.selected {
        border-width: 0 0 2px 0;
        border-color: $brand-color;
    }
    @extend .fa;
    &.is-plain-text {
        i {
            @extend .fa-eye-slash;
        }
    }
    &:not(.is-plain-text) {
        i {
            @extend .fa-eye;
        }
    }
    background-color: $input-background-color !important;
    color: $badge-text-color !important;
    cursor: pointer;
}